import { isBrowser } from "./rendering"
export const developmentEnv = process.env.NODE_ENV === `development`

export const sortNodes = (nodes, sortBy, direction) => {
  let sortByX = sortBy
  if (sortBy === `published`) {
    sortByX = `date`
  }
  if (sortBy === `menuorder`) {
    sortByX = `menuOrder`
  }
  const sortedNodes = [...nodes].sort((a, b) => {
    if (a[sortByX] < b[sortByX]) {
      return -1
    }
    if (a[sortByX] > b[sortByX]) {
      return 1
    }
    return 0
  })

  if (direction === `desc`) {
    return sortedNodes.reverse()
  }

  return sortedNodes
}

export const sortFundNodes = (nodes, sortBy, direction) => {
  let sortedNodes
  const sortByX = sortBy === `published` ? `date` : sortBy
  const acfFields = [`startDate`, `date`]
  const dateFields = [`startDate`, `date`]

  if (acfFields.includes(sortBy)) {
    sortedNodes = [...nodes].sort((a, b) => {
      if (dateFields.includes(sortBy)) {
        // Error here
        return (
          new Date(a.node.acf[sortByX]?.split(`/`).reverse().join(`-`)) -
          new Date(b.node.acf[sortByX]?.split(`/`).reverse().join(`-`))
        )
      } else {
        if (a.node?.acf[sortByX] < b.node?.acf[sortByX]) {
          return -1
        }
        if (a.node?.acf[sortByX] > b.node?.acf[sortByX]) {
          return 1
        }
        return 0
      }
    })
  } else {
    sortedNodes = [...nodes].sort((a, b) => {
      if (a.node[sortByX] < b.node[sortByX]) {
        return -1
      }
      if (a.node[sortByX] > b.node[sortByX]) {
        return 1
      }
      return 0
    })
  }

  if (direction === `desc`) {
    return sortedNodes.reverse()
  }

  return sortedNodes
}

export const sortReportsByDate = reports => {
  return [...reports].sort((a, b) => {
    return (
      new Date(`01/${b.node.acf.date}`.split(`/`).reverse().join(`-`)) -
      new Date(`01/${a.node.acf.date}`.split(`/`).reverse().join(`-`))
    )
  })
}

export const sortReportsByFund = reports => {
  return [...reports].sort((a, b) => {
    if (a.node.acf.fund?.title < b.node.acf.fund?.title) {
      return -1
    }

    if (a.node.acf.fund?.title > b.node.acf.fund?.title) {
      return 1
    }
    return 0
  })
}

export const getQueryVariable = variable => {
  if (isBrowser) {
    const query = window.location.search.substring(1)
    const vars = query.split(`&`)
    if (vars && vars.length > 0) {
      return vars.map(x => {
        return decodeURIComponent(x.split(`=`)[1])
      })[0]
    }
  }

  return null
}

// TODO: Check if this can be removed as we now use React Player for this.
export const responsiveVideos = () => {
  // List of Video Vendors embeds you want to support
  const players = [`iframe[src*="youtube.com"]`, `iframe[src*="vimeo.com"]`]

  // Select videos
  const fitVids = document.querySelectorAll(players.join(`,`))

  // If there are videos on the page...
  if (fitVids.length) {
    // Loop through videos
    for (let i = 0; i < fitVids.length; i++) {
      // Get Video Information
      const fitVid = fitVids[i]
      const parentDiv = fitVid.parentNode

      const div = document.createElement(`div`)
      div.className = `video-container aspect-video`
      parentDiv.insertBefore(div, fitVid)
      fitVid.remove()
      div.appendChild(fitVid)

      // Clear height/width from fitVid
      fitVid.removeAttribute(`height`)
      fitVid.removeAttribute(`width`)
    }
  }
}
