import ReactDOM from "react-dom/client"
import scrollToElement from "scroll-to-element"
import { responsiveVideos, developmentEnv } from "./src/utils"

import { inject } from '@vercel/analytics';

// normalize CSS across browsers
import "./src/css/normalize.css"
// Fonts
import "./src/css/fonts.css"
// Tailwind and stuff
import "./src/css/global.css"

// custom CSS styles
import "./src/css/style.css"
import "./src/css/utilities.css"

// Cookiebot overrides
import "./src/css/cookiebot.css"

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const onClientEntry = () => {
  inject();
};

export const onInitialClientRender = () => {
  /*
  // Render the data layer push script with the provided data
  const initializeDataLayer = `window.dataLayer = window.dataLayer || [];`

  // Inject the dataLayer push script
  const initDataLayer = document.createElement("script")
  initDataLayer.innerHTML = initializeDataLayer
  document.head.appendChild(initDataLayer)

  // Add GTM script dynamically
  const gtmScript = document.createElement("script")
  gtmScript.async = true
  gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_ID}`
  document.head.appendChild(gtmScript)

  */

  developmentEnv &&
    window.Cookiebot &&
    console.info(
      "Cookiebot marketing cookie status = ",
      window.Cookiebot?.consent?.marketing
    )

  setTimeout(() => {
    // If user has not accepted or interacted with the Cookiebot dialog box yet, add that to the datalayer
    if (window.Cookiebot && window?.Cookiebot?.consent?.marketing !== true) {
      window.dataLayer.push({
        event: "consent_marketing_declined",
        custom_consent_status: "consent_marketing_declined",
      })
    }
  }, 1000)
}

export function onRouteUpdate({ location, prevLocation }) {
  responsiveVideos()

  developmentEnv && console.log("new pathname", location.pathname)
  developmentEnv &&
    console.log("old pathname", prevLocation ? prevLocation.pathname : null)

  if (location && prevLocation) {
    const event = document.createEvent(`Event`)
    event.initEvent(`onRouteChange`, true, true)
    window.dispatchEvent(event)

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({
        event: 'gatsby-page-view',
        page: location.pathname,
      });
    }
  }

  if (location.hash) {
    scrollToElement(location.hash, {
      offset: -100,
      duration: 500,
    })
  }
}
